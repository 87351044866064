import React, { useEffect, useState } from 'react';
import { Input, Button } from 'reactstrap';
import { academicProgramsService } from '../../services';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { faEdit, faSearchPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { UrlEnum, usePermissions } from 'shared';
import { AcademicAffairAddLocationModal } from './academic-affair-add-location-modal';
import { AcademicAffairAddCredentialModal } from './academic-affair-add-credential-modal';
import { AcademicAffairViewAuditModal } from './academic-affair-view-audit-modal';
import { Confirm } from 'components/blocks';
import { FormSelect } from '../blocks';

const makeShortDate = date => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
};

const statusSelectOptions = [
    { label: 'New', value: 0 },
    { label: 'Active', value: 1 },
    { label: 'Suspended', value: 3 },
    { label: 'Eliminated', value: 4 },
    { label: 'Merged', value: 5 },
];
const statusChangeOptions = [
    { label: 'Active', value: 1 },
    { label: 'Suspended', value: 3 },
    { label: 'Eliminated', value: 4 },
];

const rowStyles = {
    container: provided => ({
        ...provided,
        width: 120,
    }),
};

export const AcademicAffairProgram = ({
    academicAffairsData,
    setAcademicAffairsData,
    degreeSelectOptions,
    distanceEdOptions,
    distanceEdOnlineOptions,
    locations,
    setLocations,
    fetchData,
}) => {
    const updatePermission = usePermissions({
        permissions: ['submit-changes-to-programs'],
    });
    const [locationsToAdd, setLocationsToAdd] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCredentialModalOpen, setIsCredentialModalOpen] = useState(false);
    const [isAuditModalOpen, setIsAuditOpen] = useState(false);
    const [auditId, setAuditId] = useState(0);
    const history = useHistory();
    const [confirm, setConfirm] = useState(false);
    const [onlineDropdownDisabled, setOnlineDropdownDisabled] = useState(false);
    const toggle = () => setIsModalOpen(!isModalOpen);

    const toggleCredential = () => setIsCredentialModalOpen(!isCredentialModalOpen);
    const toggleAudit = () => setIsAuditOpen(!isAuditModalOpen);

    const getOptionValue = d => d.value;

    const tableMaxHeightStyle = { maxHeight: '100px' };

    const handleProgramDetailsChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setAcademicAffairsData(prev => ({
            ...prev,
            programDetails: {
                ...prev.programDetails,
                [name]: newValue,
            },
            credentialDetails: {
                ...prev.credentialDetails,
                [name]: newValue,
            },
        }));
    };

    const updateDistanceEd = distanceEd => {
        if (distanceEd.programDistanceEd.value == 1) {
            const newEd = {
                programDistanceEdOnline: {
                    label: 'None',
                    value: 0,
                },
            };
            updateDistanceEdOnline(newEd);
        }
        setAcademicAffairsData(prev => ({
            ...prev,
            programDetails: {
                ...prev.programDetails,
                programDistanceEd: {
                    approval: distanceEd.programDistanceEd.label,
                    id: distanceEd.programDistanceEd.value,
                },
            },
        }));
    };

    const updateDistanceEdOnline = distanceEd => {
        setAcademicAffairsData(prev => ({
            ...prev,
            programDetails: {
                ...prev.programDetails,
                programDistanceEdOnline: {
                    approval: distanceEd.programDistanceEdOnline.label,
                    id: distanceEd.programDistanceEdOnline.value,
                },
            },
        }));
    };

    const toggleApproved = bool => {
        setAcademicAffairsData(prev => ({
            ...prev,
            programDetails: {
                ...prev.programDetails,
                programDistanceEdApprovedFlag: bool,
            },

            credentialDetails: {
                ...prev.credentialDetails,
            },
        }));
    };

    const toggleRelevance = bool => {
        setAcademicAffairsData(prev => ({
            ...prev,
            programDetails: {
                ...prev.programDetails,
                programCareerRelevanceFlag: bool,
            },
        }));
    };

    const convertLocationsFormat = values => {
        return values.map(value => ({
            statusId: value.statusId,
            locationId: Number(value.locationId),
        }));
    };

    const getDegreeOption = val => {
        return degreeSelectOptions.find(x => x.label === val);
    };

    const handleDegreeSelectChange = evt => {
        academicAffairsData.programDetails['degreeLevelCode'] = evt.label;
        setAcademicAffairsData({ ...academicAffairsData });
    };

    const getSelectOption = val => {
        return statusSelectOptions.find(x => x.value === val);
    };

    const handleStatusSelectChange = evt => {
        academicAffairsData.programDetails['programStatusId'] = evt.value;
        setAcademicAffairsData({ ...academicAffairsData });
    };

    const addApprovedLocation = (locationId, name) => {
        setLocationsToAdd(prev =>
            prev.concat({
                locationId: Number(locationId),
                locationName: name,
                statusId: 1,
            })
        );
    };

    useEffect(() => {
        if (academicAffairsData.programDetails == null) return;
        if (academicAffairsData.programDetails.programDistanceEd.id == 1) {
            setOnlineDropdownDisabled(true);
        } else {
            setOnlineDropdownDisabled(false);
        }
    }, [academicAffairsData]);

    const renderApprovedLocationRows = () => {
        let rows = [];
        locationsToAdd.map(location => {
            rows.push(
                <tr key={location.locationId}>
                    <td>{location.locationName}</td>
                    <td className="table-actions">
                        <div className="text-success">Pending Addition</div>
                        <div className="text-danger">
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="cursor-pointer ml-2"
                                onClick={() =>
                                    setLocationsToAdd(prev =>
                                        prev.filter(
                                            loc => loc.locationId !== location.locationId
                                        )
                                    )
                                }
                            />
                        </div>
                    </td>
                </tr>
            );
        });
        locations.map(location => {
            rows.push(
                <tr key={location.locationId}>
                    <td>{location.locationName}</td>
                    <td className="table-actions">
                        <Select
                            styles={rowStyles}
                            onChange={evt =>
                                setLocations(prev =>
                                    prev.map(loc => {
                                        if (loc.locationId === location.locationId) {
                                            return { ...loc, statusId: evt.value };
                                        }
                                        return loc;
                                    })
                                )
                            }
                            options={statusChangeOptions}
                            value={getSelectOption(location.statusId)}
                            components={{
                                IndicatorSeparator: () => null,
                            }}
                        />
                    </td>
                </tr>
            );
        });
        return rows;
    };

    const addCredential = credential => {
        credentials.push(credential);
    };

    const TurnKeyToPlainText = key => {
        switch (key) {
            case 'cipSixDigitCode':
                return `CIP Six Digit Code`;
            case 'degreeLevelCode':
                return `Degree Level Code`;
            case 'programFormalName':
                return `Program Name`;
            case 'programStartYear':
                return `Start Year`;
            case 'programEndYear':
                return `End Year`;
            case 'programStatusId':
                return `Status ID`;
            case 'programApprovalDate':
                return `Approval Date`;
            case 'programDistanceEdApprovedFlag':
                return `Distance Ed Approved Flag`;
            case 'programPffStemFlag':
                return `PFF STEM Flag`;
            case 'programCreditReqMin':
                return `Credit Requirement Minimum`;
            case 'programCreditReqMax':
                return `Credit Requirement Maximum`;
            case 'workforceReadyGrantFlag':
                return `Workforce Ready Grant Flag`;
            case 'programSuspendYear':
                return `Suspend Year`;
            case 'programDistanceEdId':
                return `Distance Ed ID`;
            case 'programDistanceEdOnlineId':
                return `Distance Ed Online ID`;
            default:
                return '';
        }
    };

    const GetAuditFieldsList = audit => {
        const retList = [];
        for (const key in audit) {
            if (
                audit[key] != null &&
                key != 'programAuditId' &&
                key != 'requesterName' &&
                key != 'programId' &&
                key != 'programApprovalDate' &&
                key != 'lastUpdateDate'
            ) {
                retList.push(TurnKeyToPlainText(key));
            }
        }
        return retList.join(', ');
    };

    const renderAuditRows = () => {
        let rows = [];
        if (audits == null) return rows;

        audits.map(audit => {
            rows.push(
                <tr key={audit.programAuditId}>
                    <td>{audit.lastUpdateDate.substring(0, 10)}</td>
                    <td>{audit.requesterName}</td>
                    <td>{GetAuditFieldsList(audit)}</td>
                    <td>
                        <Button
                            className="action-circle"
                            onClick={() => {
                                // open modal
                                setAuditId(audit.programAuditId);
                                toggleAudit(true);
                            }}
                        >
                            <FontAwesomeIcon
                                id={audit.programAuditId + '-edit'}
                                icon={faSearchPlus}
                            />
                        </Button>
                    </td>
                </tr>
            );
        });

        return rows;
    };
    const renderCredentialRows = () => {
        let rows = [];

        if (credentials == null) return rows;

        credentials.map(credential => {
            rows.push(
                <tr key={credential.credentialId}>
                    <td>{credential.credentialId}</td>
                    <td>{credential.programURL || 'N/A'}</td>
                    <td style={tableMaxHeightStyle}>
                        {credential.programDescription || 'N/A'}
                    </td>
                    <td>{credential.keywords || 'N/A'}</td>
                    <td>{credential.ctid || 'N/A'}</td>
                    <td>
                        <Button
                            className="action-circle"
                            onClick={() => {
                                const credentialId = credential.credentialId;

                                const location = {
                                    pathname: UrlEnum.ACADEMIC_AFFAIRS_CREDENTIAL,
                                    state: [{ credentialId: credentialId }],
                                };
                                history.push(location);
                            }}
                        >
                            <FontAwesomeIcon
                                id={credential.credentialId + '-edit'}
                                icon={faEdit}
                            />
                        </Button>
                    </td>
                </tr>
            );
        });

        return rows;
    };

    if (!academicAffairsData.programDetails) {
        return null;
    }

    const { credentials, audits } = academicAffairsData;
    const {
        programFormalName,
        programId,
        degreeLevelCode,
        cipSixDigitCode,
        programStatusId,
        programStartYear,
        programEndYear,
        programCreditReqMin,
        programCreditReqMax,
        programApprovalDate,
        programComments,
        programCareerRelevanceFlag,
        cheInstitutionUnitId,
    } = academicAffairsData.programDetails;
    const programDistanceEd = {
        label: academicAffairsData.programDetails.programDistanceEd.approval,
        value: academicAffairsData.programDetails.programDistanceEd.id,
    };

    const programDistanceEdOnline = {
        label: academicAffairsData.programDetails.programDistanceEdOnline.approval,
        value: academicAffairsData.programDetails.programDistanceEdOnline.id,
    };
    const { locationId, locationName } = academicAffairsData.programInstitution;

    const submitForm = async () => {
        setConfirm(false);
        const submitData = {
            programId: +programId,
            programStatusId: programStatusId,
            cipCode: cipSixDigitCode,
            degreeLevel: degreeLevelCode,
            formalName: programFormalName,
            distanceEdApproved: {
                id: programDistanceEd.value,
                approval: programDistanceEd.label,
            },
            distanceEdOnline: {
                id: programDistanceEdOnline.value,
                approval: programDistanceEdOnline.label,
            },
            comments: programComments,
            creditRequirementMinimum: programCreditReqMin,
            creditRequirementMaximum: programCreditReqMax,
            institutionId: locationId,
            approvalDate: new Date(programApprovalDate).toISOString(),
            startYear: programStartYear,
            endYear: programEndYear,
            careerRelevanceFlag: programCareerRelevanceFlag,
            locationStatusChanges: convertLocationsFormat([
                ...locationsToAdd,
                ...locations,
            ]),
        };
        console.log(submitData);
        await academicProgramsService.editAcademicProgram(submitData);
        setLocationsToAdd([]);
        fetchData();
    };
    return (
        <React.Fragment>
            <div className="sticky-footer">
                <div className="container-fluid">
                    <div className="d-md-flex align-items-center justify-content-between mb-3">
                        <a href={UrlEnum.SEARCH_ACADEMIC_AFFAIRS_PROGRAMS}>
                            <FontAwesomeIcon icon={faArrowLeft} /> back to search
                        </a>
                    </div>
                    <div className="row sticky-bar-spacer">
                        <div className="col-12">
                            <div className="card p-3 mb-3">
                                <h2 className="text-strong text-primary">
                                    {programFormalName}
                                </h2>
                                <div className="d-md-flex justify-content-md-between justify-content-center align-items-center">
                                    <ul className="d-md-flex align-items-center mr-3 p-0 mb-0 no-bullets med-type">
                                        <li className="mr-3">
                                            <label className="m-0">Program ID:</label>{' '}
                                            {programId}
                                        </li>
                                        <li className="mr-3">
                                            <label className="m-0">Institution:</label>{' '}
                                            {locationName}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card p-3">
                                <div className="row mb-4">
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>
                                            Program Name{' '}
                                            <sup className="text-danger">*</sup>
                                        </label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="Program Name"
                                            value={programFormalName}
                                            onChange={handleProgramDetailsChange}
                                            name="programFormalName"
                                        />
                                    </div>
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>
                                            Degree/Certificate Level{' '}
                                            <sup className="text-danger">*</sup>
                                        </label>
                                        <Select
                                            onChange={handleDegreeSelectChange}
                                            options={degreeSelectOptions}
                                            value={getDegreeOption(degreeLevelCode)}
                                            getOptionLabel={getOptionValue}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                    </div>
                                    <div className="col-md">
                                        <label>
                                            CIP <sup className="text-danger">*</sup>
                                        </label>
                                        <div className="form-row align-items-center">
                                            <div className="col-md-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="CIP"
                                                    value={cipSixDigitCode}
                                                    required
                                                    onChange={handleProgramDetailsChange}
                                                    name="cipSixDigitCode"
                                                />
                                            </div>
                                            <div className="col-md">
                                                <i></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl col-lg-12 mt-4 mt-xl-0">
                                        <label className="d-block">
                                            Career Relevance:{' '}
                                        </label>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="inlineRadioOptions"
                                                id="inlineRadio1"
                                                value={programCareerRelevanceFlag}
                                                checked={programCareerRelevanceFlag}
                                                onChange={() => toggleRelevance(true)}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="inlineRadio1"
                                            >
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="inlineRadioOptions"
                                                id="inlineRadio2"
                                                value={!programCareerRelevanceFlag}
                                                checked={!programCareerRelevanceFlag}
                                                onChange={() => toggleRelevance(false)}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="inlineRadio2"
                                            >
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-end mb-4">
                                    <div className="col-md-2 mb-3 mb-md-0">
                                        <label>
                                            Status <sup className="text-danger">*</sup>
                                        </label>
                                        <Select
                                            onChange={handleStatusSelectChange}
                                            options={statusSelectOptions}
                                            value={getSelectOption(programStatusId)}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                    </div>
                                    <div className="col-xl-1 col-md mb-3 mb-md-0">
                                        <label>Start</label>
                                        <Input
                                            type="number"
                                            className="form-control text-left"
                                            placeholder="YYYY"
                                            value={programStartYear}
                                            onChange={handleProgramDetailsChange}
                                            name="programStartYear"
                                        />
                                    </div>
                                    <div className="col-xl-1 col-md mb-3 mb-md-0">
                                        <label>End</label>
                                        <Input
                                            type="number"
                                            className="form-control text-left"
                                            placeholder="YYYY"
                                            value={programEndYear}
                                            onChange={handleProgramDetailsChange}
                                            name="programEndYear"
                                        />
                                    </div>
                                    <div className="col-xl-1 col-md mb-3 mb-md-0">
                                        <label>Min Credit Hours</label>
                                        <Input
                                            type="number"
                                            className="form-control"
                                            value={programCreditReqMin}
                                            onChange={handleProgramDetailsChange}
                                            name="programCreditReqMin"
                                        />
                                    </div>
                                    <div className="col-xl-1 col-md mb-3 mb-md-0">
                                        <label>Max Credit Hours</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={programCreditReqMax}
                                            onChange={handleProgramDetailsChange}
                                            name="programCreditReqMax"
                                        />
                                    </div>

                                    <div className="col-lg-2 col-md mb-3 mb-md-0">
                                        <label className="d-block">Approval Date:</label>
                                        <Input
                                            type="date"
                                            className="form-control datepicker"
                                            value={makeShortDate(programApprovalDate)}
                                            onChange={handleProgramDetailsChange}
                                            name="programApprovalDate"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-end mb-4">
                                    <div className="col-lg col-md mb-0">
                                        <FormSelect
                                            label="Delivery Mode"
                                            onChange={updateDistanceEd}
                                            name="programDistanceEd"
                                            value={programDistanceEd}
                                            isMulti={false}
                                            options={
                                                distanceEdOptions
                                                    ? distanceEdOptions.map(x => {
                                                          return {
                                                              label: x.approval,
                                                              value: x.id,
                                                          };
                                                      })
                                                    : []
                                            }
                                            fieldClassName="mb-0"
                                            placeholder={programDistanceEd}
                                        ></FormSelect>
                                    </div>
                                    <div className="col-lg col-md mb-0">
                                        <FormSelect
                                            label="Nature Of Online Program"
                                            disabled={onlineDropdownDisabled}
                                            onChange={updateDistanceEdOnline}
                                            name="programDistanceEdOnline"
                                            value={programDistanceEdOnline}
                                            isMulti={false}
                                            options={
                                                distanceEdOnlineOptions
                                                    ? distanceEdOnlineOptions.map(x => {
                                                          return {
                                                              label: x.approval,
                                                              value: x.id,
                                                          };
                                                      })
                                                    : []
                                            }
                                            fieldClassName="mb-0"
                                            placeholder={programDistanceEdOnline}
                                        ></FormSelect>
                                    </div>
                                </div>
                                <hr />
                                <div className="row gutter-60">
                                    <div className="col-md-7">
                                        <div className="d-md-flex align-items-center justify-content-between text-md-left text-center mb-3">
                                            <h3 className="text-primary mb-3 mb-md-0">
                                                Approved Locations
                                            </h3>
                                            <button
                                                type="button"
                                                onClick={() => setIsModalOpen(true)}
                                                className="btn btn-sm btn-primary"
                                            >
                                                <i className="far fa-plus"></i> Add
                                                Location
                                            </button>
                                        </div>
                                        <div className="input-group input-group-btn input-group-sm mb-3 d-none">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="search approved locations..."
                                            />
                                            <div className="input-group-append white hide">
                                                <span className="input-group-text px-3">
                                                    <div className="body-text-link text-primary">
                                                        <i className="fal fa-times"></i>{' '}
                                                        clear
                                                    </div>
                                                </span>
                                            </div>
                                            <div className="input-group-append">
                                                <button
                                                    className="btn btn-outline-primary"
                                                    type="button"
                                                >
                                                    <i className="fa fa-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Location</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {renderApprovedLocationRows()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <label>
                                            Comments{' '}
                                            <span className="text-muted">(optional)</span>
                                        </label>
                                        <textarea
                                            className="form-control"
                                            placeholder="comments..."
                                            rows="8"
                                            value={programComments}
                                            onChange={handleProgramDetailsChange}
                                            name="programComments"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card p-3 mb-3">
                                {(credentials.length == 0) && <button
                                    type="button"                                    
                                    onClick={() => setIsCredentialModalOpen(true)}                                
                                    className="btn btn-sm btn-primary"
                                    disabled={false}>
                                    <i className="far fa-plus"></i> Add
                                        Credential
                                </button>}
                            </div>
                            {(credentials.length != 0) &&
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card p-3 mb-3">
                                            <div className="table-responsive">
                                                
                                                <table className="table table-bordered table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>CHE Credential Id</th>
                                                            <th>Url</th>
                                                            <th>Description</th>
                                                            <th>Keywords</th>
                                                            <th>CTID</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {renderCredentialRows()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            } */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="card p-3 mb-3">
                                        <div className="d-md-flex align-items-center justify-content-between text-md-left text-center mb-3">
                                            <h3 className="text-primary mb-3 mb-md-0">
                                                Program Audit
                                            </h3>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Date Modified</th>
                                                        <th>User</th>
                                                        <th>Fields Changed</th>
                                                        <th>View</th>
                                                    </tr>
                                                </thead>
                                                <tbody>{renderAuditRows()}</tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {updatePermission && (
                        <div className="sticky-bottom-bar">
                            <div className="d-flex align-items-center justify-content-end">
                                <a
                                    className="btn btn-outline-primary btn-lg mr-2"
                                    href={UrlEnum.SEARCH_ACADEMIC_AFFAIRS_PROGRAMS}
                                >
                                    Cancel
                                </a>
                                <Button
                                    type="button"
                                    className="btn btn-success btn-lg px-3"
                                    onClick={() => {
                                        setConfirm(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faCheck} /> Save Changes
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <AcademicAffairAddLocationModal
                isOpen={isModalOpen}
                toggle={toggle}
                addApprovedLocation={addApprovedLocation}
                existingLocations={[...locations, ...locationsToAdd]}
                locationId={locationId}
            />
            <AcademicAffairAddCredentialModal
                isOpen={isCredentialModalOpen}
                toggle={toggleCredential}
                addCredential={addCredential}
                programId={programId}
                institutionUnitID={cheInstitutionUnitId}
            />
            <AcademicAffairViewAuditModal
                isOpen={isAuditModalOpen}
                toggle={toggleAudit}
                auditsList={audits}
                auditId={auditId}
                turnKey={TurnKeyToPlainText}
            />
            <Confirm
                isOpen={confirm}
                toggle={() => setConfirm(false)}
                onConfirm={submitForm}
                title="Confirm Changes"
                message="Are you sure you want to submit these changes?"
            />
        </React.Fragment>
    );
};
